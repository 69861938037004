<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              class="mr-1"
              @click="onShowEditForm(row.item)"
            >
              Update
            </b-button>
            <b-button
              size="sm"
              class="mr-1"
              variant="success"
              @click="onShowApprovalForm(row.item, 'approve')"
            >
              Approve
            </b-button>
            <b-button
              size="sm"
              variant="danger"
              @click="onShowApprovalForm(row.item, 'disapprove')"
            >
              Disapprove
            </b-button>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-account-form"
      scrollable
      no-close-on-backdrop
      :title="modalTitle"
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <b-row>
            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="business name"
                vid="business_name"
                rules="required|max:200"
              >
                <b-form-group>
                  <label for="business_name">
                    <strong>Business Name</strong>
                  </label>
                  <b-input
                    id="business_name"
                    v-model="account.business_name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter business name"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="business address"
                vid="business_address"
                rules="required|max:200"
              >
                <b-form-group>
                  <label for="business_address">
                    <strong>Business Address</strong>
                  </label>
                  <b-textarea
                    id="business_address"
                    v-model="account.business_address"
                    rows="6"
                    max-rows="6"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter business address"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="owner mobile number"
                vid="owner_mobile"
                rules="required|min:11|max:11"
              >
                <b-form-group>
                  <label for="owner_mobile">
                    <strong>Owner Mobile Number</strong>
                  </label>
                  <b-input
                    id="owner_mobile"
                    v-model="account.owner_mobile"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter owner mobile number"
                    autocomplete="off"
                    maxlength="11"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="owner address"
                vid="owner_address"
                rules="required|max:200"
              >
                <b-form-group>
                  <label for="owner_address">
                    <strong>Owner Address</strong>
                  </label>
                  <b-textarea
                    id="owner_address"
                    v-model="account.owner_address"
                    rows="6"
                    max-rows="6"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter owner address"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="remarks"
                vid="remarks"
                rules="required|max:500"
              >
                <b-form-group>
                  <label for="remarks">
                    <strong>Source Remarks</strong>
                  </label>
                  <b-textarea
                    id="remarks"
                    :value="account.remarks"
                    rows="6"
                    max-rows="6"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter remarks"
                    autocomplete="off"
                    disabled
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? "Update Record" : "Save Record" }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="modal-approve-form"
      scrollable
      no-close-on-backdrop
      title="Approve Pending Account"
      @ok="onConfirmApproval"
    >
      <ValidationObserver ref="form2">
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <b-row>
            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="card code"
                vid="card_code"
                rules="required|min:7|max:7"
              >
                <b-form-group>
                  <label for="card_code">
                    <strong>Card Code (SAP)</strong>
                  </label>
                  <b-input
                    id="card_code"
                    v-model="approveForm.card_code"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter card code"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="distribution"
                vid="distribution"
                rules="required"
              >
                <b-form-group>
                  <label for="distribution">
                    <strong>Distribution (SAP)</strong>
                  </label>
                  <b-form-select
                    id="distribution"
                    v-model="approveForm.distribution"
                    :options="list.distributions"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        -- Please Select Distribution --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <label>Business Name</label>
                <b-input
                  type="text"
                  :value="account.business_name"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <label>Business Address</label>
                <b-textarea
                  :value="account.business_address"
                  rows="6"
                  max-rows="6"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <label>Owner Name</label>
                <b-input
                  type="text"
                  :value="account.owner_name"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <label>Owner Address</label>
                <b-textarea
                  :value="account.owner_address"
                  rows="6"
                  max-rows="6"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="onSetAction(ok, 'approve')"
        >
          Approve
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="modal-disapprove-form"
      scrollable
      no-close-on-backdrop
      title="Disapprove Pending Account"
      @ok="onConfirmApproval"
    >
      <ValidationObserver ref="form3">
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <label>Business Name</label>
                <b-input
                  type="text"
                  :value="account.business_name"
                  disabled
                />
              </b-form-group>
            </b-col>

            <!-- <b-col cols="12">
              <b-form-group>
                <label>Business Address</label>
                <b-textarea
                  :value="account.business_address"
                  rows="6"
                  max-rows="6"
                  disabled
                />
              </b-form-group>
            </b-col> -->

            <b-col cols="12">
              <b-form-group>
                <label>Owner Name</label>
                <b-input
                  type="text"
                  :value="account.owner_name"
                  disabled
                />
              </b-form-group>
            </b-col>

            <!-- <b-col cols="12">
              <b-form-group>
                <label>Owner Address</label>
                <b-textarea
                  :value="account.owner_address"
                  rows="6"
                  max-rows="6"
                  disabled
                />
              </b-form-group>
            </b-col> -->

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="disapprove reason"
                vid="disapprove_reason"
                rules="required|max:500"
              >
                <b-form-group>
                  <label for="disapprove_reason">
                    <strong>Disapprove Reason</strong>
                  </label>
                  <b-textarea
                    id="disapprove_reason"
                    v-model="disapproveForm.disapprove_reason"
                    rows="6"
                    max-rows="6"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter disapprove reason"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="onSetAction(ok, 'disapprove')"
        >
          Disapprove
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, AgentPendingAccountService, AgentApprovalService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AgentPendingAccounts',

  middleware: ['auth', 'agent'],

  metaInfo () {
    return {
      title: 'Pending Accounts'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      current: {
        action: ''
      },
      list: {
        distributions: []
      },
      account: {
        id: 0,
        business_name: '',
        business_address: '',
        owner_name: '',
        owner_mobile: '',
        owner_address: '',
        remarks: ''
      },
      approveForm: {
        id: 0,
        card_code: '',
        distribution: null
      },
      disapproveForm: {
        id: 0,
        disapprove_reason: ''
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'business_name' },
          { key: 'business_address' },
          { key: 'status', sortable: true },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.state.editing ? 'Edit Account' : 'New Account'
    }
  },

  mounted () {
    core.index()
    this.getDistributions()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await AgentPendingAccountService.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getDistributions () {
      await SharedListService.getDistributions().then(({ data }) => {
        this.list.distributions = data.map(item => {
          return {
            value: item.id,
            text: item.distribution_name
          }
        })
      })
    },

    onShowAddForm () {
      this.state.editing = false
      this.account.id = 0
      this.account.business_name = ''
      this.account.business_address = ''
      this.account.owner_name = ''
      this.account.owner_mobile = ''
      this.account.owner_address = ''
      this.account.remarks = ''
      this.$bvModal.show('modal-account-form')
    },

    onShowEditForm (current) {
      this.state.editing = true
      this.account.id = current.id
      this.account.business_name = current.business_name
      this.account.business_address = current.business_address
      this.account.owner_name = current.owner_name
      this.account.owner_mobile = current.owner_mobile
      this.account.owner_address = current.owner_address
      this.account.remarks = current.remarks
      this.$bvModal.show('modal-account-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          let title = 'Do you really want to create account?'

          if (this.state.editing) {
            title = 'Do you really want to save changes?'
          }

          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              if (this.state.editing) {
                return this.onFormPutSubmit()
              } else {
                return this.onFormPostSubmit()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        AgentPendingAccountService.put(this.account).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-account-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.table.localItems, { id: data.account.id })
            row.business_name = data.account.business_name
            row.business_address = data.account.business_address
            row.owner_name = data.account.owner_name
            row.owner_mobile = data.account.owner_mobile
            row.owner_address = data.account.owner_address
            row.remarks = data.account.remarks
            row.updated_at = data.account.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              resolve(error)
            }
          }
        })
      })
    },

    async onShowApprovalForm (current, action) {
      this.account.business_name = current.business_name
      this.account.business_address = current.business_address
      this.account.owner_name = current.owner_name
      this.account.owner_mobile = current.owner_mobile
      this.account.owner_address = current.owner_address

      if (action === 'approve') {
        this.approveForm.id = current.id
        this.approveForm.card_code = ''
        this.approveForm.distribution = null
        this.$bvModal.show('modal-approve-form')
      } else {
        this.disapproveForm.id = current.id
        this.disapproveForm.disapprove_reason = ''
        this.$bvModal.show('modal-disapprove-form')
      }
    },

    onSetAction (callback, action) {
      this.current.action = action
      callback()
    },

    async onConfirmApproval (bvModalEvt) {
      bvModalEvt.preventDefault()
      const form = this.current.action === 'approve' ? this.$refs.form2 : this.$refs.form3
      await form.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: `Are you sure you want to ${this.current.action} this pending account?`,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: async () => {
              if (this.current.action === 'approve') {
                return this.onApprovePutForm()
              } else {
                return this.onDisapprovePutForm()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onApprovePutForm () {
      return new Promise(resolve => {
        this.state.busy = true
        AgentApprovalService.approveAccount(this.approveForm).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-approve-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form2.setErrors(error.message)
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              resolve(error)
            }
          }
        })
      })
    },

    async onDisapprovePutForm () {
      return new Promise(resolve => {
        this.state.busy = true
        AgentApprovalService.disapproveAccount(this.disapproveForm).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-disapprove-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form3.setErrors(error.message)
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              resolve(error)
            }
          }
        })
      })
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    }
  }
}
</script>
